import React, { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import bg from "../../../assets/img/bg3.jpg";
import axios from "axios";
const Research = ({match}) => {
  /*
  * 목록에 설문조사 갯수
  *
  * */
  const history = useHistory();

  const {company} = match.params;

  useEffect(() => {
    if (company === undefined) {
      alert("올바르지 않은 주소입니다.")
      history.push("/");
    }
    check_research_company(company);
  }, [])

  const check_research_company = async (company) => {
    axios.get("http://18.179.138.213:3001/research?companyCd=" + company).then(response => {
    //axios.get("http://18.179.138.213:3001/research?companyCd=" + company).then(response => {
      if (response.data.code == 200) {
        setCompanyNm(response.data.data);
      } else {
        alert(response.data.message);
        history.push("/");
      }
    }).catch(err => {
      alert("진행되지 않는 설문조사입니다.");
      history.push("/");
    });
  }
  const [companyNm, setCompanyNm] = useState("");
  const [inputs, setInputs] = useState({
    agree : "0",
    a1 : "",
    a2 : "",
    a3 : "0",
    a4 : "0",
    a42 : "0",
    a5 : "",
    a52: "",
    a53: "",
    a6 : "0",
    a7 : "",
    a72 : "0",
    a73 : "0",
    a8 : "",
    a82 : "",
    a83 : "",
    a9 : "",
    a92 : "0",
    a93 : "0",
    b1 : [],
    b2 : "0",
    b3 : "0",
    b32 : [],
    b321: "0", b322: "0", b323: "0", b324: "0", b325: "0", // 질병 진단 완치 여부
    b4 : "0",
    b42 : [],
    b5 : "0",
    c1 : "0",
    c12 : [],
    c212 : "0", c213 : "0", c214 : "0", c215 : "0", c216 : "0", c216other: "",  // 설문 조사 목 ...,
    c221 : "0", c222 : "0", c223 : "0", c224 : "0", c225 : "0", c226 : "0", c226other: "",
    c231 : "0", c232 : "0", c233 : "0", c234 : "0", c235 : "0", c236 : "0", c236other: "",
    c241 : "0", c242 : "0", c243 : "0", c244 : "0", c245 : "0", c246 : "0", c246other: "",
    c252 : "0", c253 : "0", c254 : "0", c255 : "0", c256 : "0", c256other: "",
    c261 : "0", c262 : "0", c263 : "0", c264 : "0", c265 : "0", c266 : "0", c266other: ""

  })


  const {agree, a1, a2, a3, a4, a42, a5, a52, a53, a6, a7, a72, a73, a8,a82, a83, a9,a92,a93,
    b1, b2, b3, b32, b321, b322, b323, b324, b325, b4, b42, b5,
    c1, c12, c212, c213, c214, c215, c216, // 설문 조사 목 ...
    c221, c222, c223, c224, c225, c226,
    c231, c232, c233, c234, c235, c236,
    c241, c242, c243, c244, c245, c246,
    c252, c253, c254, c255, c256,
    c261, c262, c263, c264, c265, c266} = inputs;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value
    });
  }

  const handleChangeNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
      if (value == '' || re.test(value)) {
         setInputs({
          ...inputs,
          [name]: value
         })
      } else {
        alert("숫자만 입력 가능합니다!");
      }
  }
  const handleCheckbox = (e) => {
    const {name, value} = e.target;
    if (inputs[name].indexOf(value) !== -1) {
      // 삭제
      setInputs({
        ...inputs,
        [name]: inputs[name].filter(idx => idx !== value)
      })
    }else {
      setInputs({
        ...inputs,
        [name]: inputs[name].concat(value)
      })
    }
  }
  const parse_data =(datas) => {
    let data = JSON.parse(JSON.stringify(datas));
    data.company = company;
    data.b1 = data.b1.toString()
    data.b32 = data.b32.toString()
    data.b42 = data.b42.toString()
    data.c12 = data.c12.toString()
    return data
  }
  const handleSave = () => {

    if (check_value(inputs)) {
      return;
    }
    let post_data = parse_data(inputs)

    axios.post("http://18.179.138.213:3001/research",  post_data).then(response => {
      if (response.data.code == 200) {
        alert("설문조사에 참여해주셔서 감사합니다.")
        history.push("/")
      }else {
        alert("입력값을 확인하세요")
      }
    }).catch(err => {
      alert("입력값을 확인하세요")
    })
  }

  return (
    <div className="sub_container">
      {/*-- 타이틀 --*/}
      <div className="sub_title_banner" style={{ background: `url(${bg}) center center no-repeat` }}>
        <h2 className="tit">설문조사</h2>
      </div>
      {/*--// 타이틀 --*/}
      {/*-- 리본바 --*/}
      <div className="sub_reborn">
        <ul>
          <li><Link to="/">홈</Link></li>
          <li><Link to="/research">설문조사</Link></li>
        </ul>
      </div>
      {/*--// 리본바 --*/}
      <div className="container" style={{ color: "black" }}>
        {/*--// 헤더 --*/}
        <div style={{ textAlign: "center" }}>
          <h3 style={{ fontWeight: "bold" }}>근골격계질환 증상조사표</h3>

          <div style={{ border: "1px solid gray", padding: "10px", margin: "10px 0px 10px 0px" }}>
            <p style={{ color: "black" }}>안녕하십니까? 본 조사는 산업안전보건법 제39조 ‘보건상의 조치에 대한 사업주의 의무(단순반복작업 또는 인체에 과도한 부담을 주는 작업에 의한 건강장해 예방조치 사항)’를 수행하기 위한 과정으로 이루어지고 있습니다.</p>
            <p style={{ color: "black" }}><strong>한국재해예방관리원(주)</strong>에서는 <strong>{companyNm}</strong>으로부터 조사를 의뢰받아 근골격계질환 예방을 위한 조사 설문조사를 진행하고 있습니다.</p>
            <p style={{ color: "black" }}>설문의 결과는 조사 이외의 목적으로 활용되지 않을 것이며 개인정보와 관련된 사항은 철저히 보장될 것입니다. 모쪼록 바쁘시더라도 솔직하고 꼼꼼한 답변 부탁드립니다.</p>
          </div>
        </div>

        {/*--// 동의서 --*/}
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <h3 style={{ fontWeight: "bold" }}>동의서</h3>
          <div style={{ border: "1px solid gray", padding: "10px", margin: "10px 0px 10px 0px" }}>

            <p style={{ color: "black" }}>본 조사에서는 ‘개인정보보호법’에 근거하여 개인정보의 수집ㆍ이용 등에 관한 동의를 받고 있으며, 보유하게 되는 개인 정보들은 관계 법령에 따라 적법하게 처리하고, <br /><strong>{companyNm}</strong>의 분석 관련 자료로만 사용됩니다.</p>
            <p style={{ color: "black" }}>위의 사항을 읽고 개인정보의 수집에 동의하십니까?</p>

            <input type="radio" name="agree" value="1"  onChange={handleChange} /><label>동의</label>&nbsp;&nbsp;
            <input type="radio" name="agree" value="0"  onChange={handleChange} /><label>동의하지 않음</label>
          </div>
        </div>
        {/*조사표*/}
        <form>
        <div className="research-table"style={agree === "1" ? { display: "block" } : { display: "none" }}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>근골격계질환 증상조사표</h3>
          <p>아래 사항을 직접 기입해 주시기 바랍니다.</p>
          <label style={{color : "red"}}>* 표시는 필수입니다.</label>
          <div className="research-content">
            {/* section 1 개인 정보*/}
            <div>
              <b>1. 성명* </b><br/><input type="text" name="a1" onChange={handleChange}/>
            </div>
            <hr />
            <div>
              <b>2. 연령*</b><br/>만 <input name="a2" value={a2} min="0"  onChange={handleChangeNumber} /> 세
            </div>
            <hr />
            <div>
              <b>3. 성별*</b><br/>
              <label><input type="radio" name="a3" value="1" onChange={handleChange}/>남</label>
              <label> <input type="radio" name="a3" value="2" onChange={handleChange}/>여</label>
            </div>
            <hr />
            <div>
              <b>4. 현 직장경력*</b> <br/>
              <input name="a4" value={a4} onChange={handleChangeNumber} /> 년 &nbsp;
              <input name="a42" value={a42} onChange={handleChangeNumber} /> 개월
            </div>
            <hr />
            <div>
              <div style={{marginBottom: "10px"}}>
              <b>5. 작업부서*</b><br/>
              </div>
              {/* 대분류 */}
              <div>
                <div>
                  <label><b>제조1팀</b></label>
                  <label><input type="radio" name="a5" value="1" onChange={handleChange} />SMT파트</label>
                  <label><input type="radio" name="a5" value="2" onChange={handleChange} />금형파트</label>
                  <label><input type="radio" name="a5" value="3" onChange={handleChange} />사출파트</label>
                  <label><input type="radio" name="a5" value="4" onChange={handleChange} />조립파트</label>
                  <label><input type="radio" name="a5" value="5" onChange={handleChange} />도장파트</label>
                  <hr/>
                </div>
                <div>
                  <label><b>제조2팀</b></label>
                  <label><input type="radio" name="a5" value="6" onChange={handleChange} />조립파트</label>
                  <hr/>
                </div>
                <div>
                  <label><b>연구개발팀</b></label>
                  <label><input type="radio" name="a5" value="7" onChange={handleChange} />연구개발팀</label>
                  <hr/>
                </div>
                <div>
                  <label><b>품질경영팀</b></label>
                  <label><input type="radio" name="a5" value="8" onChange={handleChange} />품질경영팀</label>
                  <hr/>
                </div>
                <div>
                  <label><b>구매팀</b></label>
                  <label><input type="radio" name="a5" value="9" onChange={handleChange} />물류</label>
                  <hr/>
                </div>
                <div>
                  <label><b>경영지원</b></label>
                  <label><input type="radio" name="a5" value="10" onChange={handleChange} />사무직(경영지원, 재무, 인사 등)</label>
                  <label><input type="radio" name="a5" value="11" onChange={handleChange} />유틸리티</label>
                  <label><input type="radio" name="a5" value="12" onChange={handleChange} />식당</label>
                  <label><input type="radio" name="a5" value="13" onChange={handleChange} />미화</label>
                  <label><input type="radio" name="a5" value="14" onChange={handleChange} />보안</label>
                  <label><input type="radio" name="a5" value="15" onChange={handleChange} />폐기물관리</label>
                  <hr/>
                </div>
                <div>
                  <label><input type="radio" name="a5" value="16" onChange={handleChange} /><b>기타</b></label>
                  <label><input type="text" name="a52" value={a52} onChange={handleChange} /></label>
                  <hr/>
                </div>
              </div>

            </div>
            <div>
              <b>6. 결혼여부*</b>  <br/>
              <label><input type="radio" name="a6" value="1"  onChange={handleChange} />기혼</label>
              <label> <input type="radio" name="a6" value="2"  onChange={handleChange} />미혼</label>
            </div>
            <hr />
            <div>
              <b>7. 현재 하시는 작업 (구체적으로)* </b><br/>
              작업 내용 : <input type="text" style={{width : "50%"}} name="a7" onChange={handleChange}/> <br/><br/>
              작업 기간 : <input name="a72" value={a72} onChange={handleChangeNumber} />년 &nbsp;<input name="a73" value={a73}onChange={handleChangeNumber} />개월
            </div>
            <hr />
            <div>
              <b>8. 1일 근무시간*</b> <br/>
              <label>근무 <input name="a8" value={a8} onChange={handleChangeNumber} /> 시간 중 (식사시간 제외) </label>
              <label>휴식시간 <input name="a82" value={a82} onChange={handleChangeNumber} min="0" max="60" />분</label>  <label><input name="a83" value={a83} onChange={handleChangeNumber} />회 휴식</label><br/>
              ex) 총 9시간 근무 / 점심시간 1시간 / 10분씩 4회 휴식일 때 -> 근무 8시간 중 휴식시간 10분 4회 휴식
            </div>
            <hr />
            <div>
              <b>9. 현작업 전에 했던 작업 (없으면 공백)</b><br />
              작업 내용 : <input type="text" style={{width : "50%"}} name="a9" onChange={handleChange}/> <br/><br/>
              작업 기간 : <input name="a92" value={a92} onChange={handleChangeNumber} />년 &nbsp;<input name="a93" value={a93} onChange={handleChangeNumber} />개월
            </div>
            <hr />
          </div>
          <div>
            <p><b> 1-1 규칙적인(한번에 30분 이상, 1주일에 적어도 2-3회 이상) 여가 및 취미활동을
  하고 계시는 곳을 선택하여 주십시오.*</b></p>
            <div>
              <label><input type="checkbox" name="b1" value="1" onChange={handleCheckbox} />컴퓨터 관련활동</label>
              <label><input type="checkbox" name="b1" value="2" onChange={handleCheckbox}/>악기연주(피아노, 바이올린 등)</label>
              <label><input type="checkbox" name="b1" value="3" onChange={handleCheckbox}/>뜨개질 자수, 붓글씨</label>
              <label><input type="checkbox" name="b1" value="4" onChange={handleCheckbox}/>테니스/배드민턴/스쿼시</label>
              <label><input type="checkbox" name="b1" value="5" onChange={handleCheckbox}/>축구/족구/농구/스키</label>
              <label><input type="checkbox" name="b1" value="6" onChange={handleCheckbox}/>해당사항 없음</label>
            </div>
          </div>
          <hr/>
          <div>
            <p><b> 1-2. 귀하의 하루 평균 가사노동시간(밥하기, 빨래하기, 청소하기, 2살 미만의 아이 돌
보기 등)은 얼마나 됩니까 ?*</b></p>
            <div>
              <label><input type="radio" name="b2" value="1" onChange={handleChange}/>거의 하지 않는다</label>
              <label><input type="radio" name="b2" value="2" onChange={handleChange}/>1시간 미만</label>
              <label><input type="radio" name="b2" value="3" onChange={handleChange}/>1-2시간 미만</label>
              <label><input type="radio" name="b2" value="4" onChange={handleChange}/>2-3시간 미만</label>
              <label><input type="radio" name="b2" value="5" onChange={handleChange}/>3시간 이상</label>
            </div>
          </div>
          <hr/>
          <div>
            <p> <b>1-3. 귀하는 의사로부터 류머티스 관절염, 당뇨병, 루프스병, 통풍, 알코올중독질병에 대해 진단을 받은 적이 있습니까?*</b></p>
            <div>
            <label><input type="radio" name="b3" value="1" onChange={handleChange}/>아니요</label>
              <label><input type="radio" name="b3" value="2" onChange={handleChange}/>예</label>

            </div>
            <div style={b3 === "2" ? { display: "block" } : { display: "none" }} >
              <p><b>진단받은 적이 있는 병명을 선택하십시오*</b></p>
              <div style={{display : "flex"}}>
                <label><input type="checkbox" name="b32" value="1" onChange={handleCheckbox}/>류머티스 관절염</label>
                <div style={b32.indexOf("1") !== -1 ? { display: "block"} : { display: "none" }}>
                  <label style={{color : "blue"}}> &gt; 현재 상태는? &nbsp;&nbsp;</label>
                  <label><input type="radio" name="b321" value="1" onChange={handleChange}/>완치&nbsp;</label>
                  <label><input type="radio" name="b321" value="2" onChange={handleChange}/>치료나 관찰 중</label>
                </div>
              </div>
              <div style={{display : "flex"}}>
                <label><input type="checkbox" name="b32" value="2" onChange={handleCheckbox}/>당뇨병</label>
                <div style={b32.indexOf("2") !== -1 ? { display: "block"} : { display: "none" }}>
                  <label style={{color : "blue"}}> &gt; 현재 상태는? &nbsp;&nbsp;</label>
                  <label><input type="radio" name="b322" value="1" onChange={handleChange}/>완치&nbsp;</label>
                  <label><input type="radio" name="b322" value="2" onChange={handleChange}/>치료나 관찰 중</label>
                </div>
              </div>
              <div style={{display : "flex"}}>
                <label><input type="checkbox" name="b32" value="3" onChange={handleCheckbox}/>루프스병</label>
                <div style={b32.indexOf("3") !== -1 ? { display: "block"} : { display: "none" }}>
                  <label style={{color : "blue"}}> &gt; 현재 상태는? &nbsp;&nbsp;</label>
                  <label><input type="radio" name="b323" value="1" onChange={handleChange}/>완치&nbsp;</label>
                  <label><input type="radio" name="b323" value="2" onChange={handleChange}/>치료나 관찰 중</label>
                </div>
              </div>
              <div style={{display : "flex"}}>
                <label><input type="checkbox" name="b32" value="4" onChange={handleCheckbox}/>통풍</label>
                <div style={b32.indexOf("4") !== -1 ? { display: "block"} : { display: "none" }}>
                  <label style={{color : "blue"}}> &gt; 현재 상태는? &nbsp;&nbsp;</label>
                  <label><input type="radio" name="b324" value="1" onChange={handleChange}/>완치&nbsp;</label>
                  <label><input type="radio" name="b324" value="2" onChange={handleChange}/>치료나 관찰 중</label>
                </div>
              </div>
              <div style={{display : "flex"}}>
                <label><input type="checkbox" name="b32" value="5" onChange={handleCheckbox}/>알코올중독</label>
                <div style={b32.indexOf("5") !== -1 ? { display: "block"} : { display: "none" }}>
                  <label style={{color : "blue"}}> &gt; 현재 상태는? &nbsp;&nbsp;</label>
                  <label><input type="radio" name="b325" value="1" onChange={handleChange}/>완치&nbsp;</label>
                  <label><input type="radio" name="b325" value="2" onChange={handleChange}/>치료나 관찰 중</label>
                </div>
              </div>
            </div>


          </div>
          <hr/>
          <div>
            <p> <b>1-4. 과거에 운동 중 혹은 사고로(교통사고, 넘어짐, 추락 등) 인해 손/손가락/손목, 팔/팔꿈치, 어깨, 목, 허리, 다리/발 부위를 다친 적인 있습니까 ?*</b></p>
            <div>
            <label><input type="radio" name="b4" value="1" onChange={handleChange}/>아니요</label>
            <label><input type="radio" name="b4" value="2" onChange={handleChange}/>예</label>

            </div>
            <div style={b4 === "2" ? { display: "block" } : { display: "none" }}>
              <p><b>'예'인 경우 상해 부위는 ? </b></p>
              <label><input type="checkbox" name="b42" value="1" onChange={handleCheckbox}/>손/손가락/손목</label>
              <label><input type="checkbox" name="b42" value="2" onChange={handleCheckbox}/>팔/팔꿈치</label>
              <label><input type="checkbox" name="b42" value="3" onChange={handleCheckbox}/>어깨</label>
              <label><input type="checkbox" name="b42" value="4" onChange={handleCheckbox}/>목</label>
              <label><input type="checkbox" name="b42" value="5" onChange={handleCheckbox}/>허리</label>
              <label><input type="checkbox" name="b42" value="6" onChange={handleCheckbox}/>다리/발</label>
            </div>
          </div>
          <hr/>
          <div>
            <p><b>1-5. 현재 하고 계시는 일의 육체적 부담 정도는 어느 정도라고 생각합니까 ?* </b></p>
            <div>
              <label><input type="radio" name="b5" value="1" onChange={handleChange}/>전혀 힘들지 않음</label>
              <label><input type="radio" name="b5" value="2" onChange={handleChange}/>견딜만 함</label>
              <label><input type="radio" name="b5" value="3" onChange={handleChange}/>약간 힘듦</label>
              <label><input type="radio" name="b5" value="4" onChange={handleChange}/>힘듦</label>
              <label><input type="radio" name="b5" value="5" onChange={handleChange}/>매우 힘듦</label>
            </div>
          </div>
          <hr/>
          <div>
            <p><b>2. 지난 1년 동안 귀하의 작업과 관련하여 목, 어깨, 팔/팔꿈치, 손/손가락/손
목, 허리, 다리/발 중 어느 한 부위에서라도 통증, 쑤심, 찌릿찌릿함, 뻣뻣함, 화끈거
림, 무감각 등을 느끼신 적이 있습니까 ?*</b></p>
            <div>
            <label><input type="radio" name="c1" value="1" onChange={handleChange}/>아니요 (수고하셨습니다. 설문을 제출하여 주십시오.)</label>
              <label><input type="radio" name="c1" value="2" onChange={handleChange}/>예 (아래 표의 통증부위에 체크하고, 내려가며 해당 통증부위의 해당사항에 체크해 주십시오.)</label>

            </div>
            <div style={c1 === "2" ? { display: "block" } : { display: "none" }}>
              <label><input type="checkbox" name="c12" value="1" onChange={handleCheckbox}/>목</label>
              <label><input type="checkbox" name="c12" value="2" onChange={handleCheckbox}/>어깨</label>
              <label><input type="checkbox" name="c12" value="3" onChange={handleCheckbox}/>팔/팔꿈치</label>
              <label><input type="checkbox" name="c12" value="4" onChange={handleCheckbox}/>손/손목/손가락</label>
              <label><input type="checkbox" name="c12" value="5" onChange={handleCheckbox}/>허리</label>
              <label><input type="checkbox" name="c12" value="6" onChange={handleCheckbox}/>다리/발</label>

            {/* 목 */}
            <div style={c12.indexOf("1") !== -1 ? { display: "block" } : { display: "none" }}>
              <div style={{border : "1px solid gray", padding : "10px", marginBottom : "20px"}}>
                <p><b>목</b></p>
                <div>
                  <p><b>2. 한번 아프기 시작하면 통증 기간은 얼마 동안 지속됩니까?</b></p>
                  <div>
                    <label><input type="radio" name="c212" value="1" onChange={handleChange}/>1일 미만</label>
                    <label><input type="radio" name="c212" value="2" onChange={handleChange}/>1일 ~ 1주일 미만</label>
                    <label><input type="radio" name="c212" value="3" onChange={handleChange}/>1주일 ~ 1달 미만</label>
                    <label><input type="radio" name="c212" value="4" onChange={handleChange}/>1달 ~ 6개월 미만</label>
                    <label><input type="radio" name="c212" value="5" onChange={handleChange}/>6개월 이상</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>3. 그때의 아픈 정도는 어느 정도 입니까? </p>
                  <div>
                    <label><input type="radio" name="c213" value="1" onChange={handleChange}/>약한 통증</label>
                    <label><input type="radio" name="c213" value="2" onChange={handleChange}/>중간 통증</label>
                    <label><input type="radio" name="c213" value="3" onChange={handleChange}/>심한 통증</label>
                    <label><input type="radio" name="c213" value="4" onChange={handleChange}/>매우 심한 통증</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>4. 지난 1년 동안 이러한 증상을 얼마나 자주 경험하셨습니까? </p>
                  <div>
                    <label><input type="radio" name="c214" value="1" onChange={handleChange}/>6개월에 1번</label>
                    <label><input type="radio" name="c214" value="2" onChange={handleChange}/>2~3달에 1번</label>
                    <label><input type="radio" name="c214" value="3" onChange={handleChange}/>1달에 1번</label>
                    <label><input type="radio" name="c214" value="4" onChange={handleChange}/>매일</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>5. 지난 1주일 동안에도 이러한 증상이 있었습니까?</p>
                  <div>
                    <label><input type="radio" name="c215" value="1" onChange={handleChange}/>아니요</label>
                    <label><input type="radio" name="c215" value="2" onChange={handleChange}/>예</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>6. 지난 1년 동안 이러한 통증으로 인해 어떤 일이 있었습니까?</p>
                  <div>
                    <label><input type="radio" name="c216" value="1" onChange={handleChange}/>병원, 한의원 치료</label>
                    <label><input type="radio" name="c216" value="2" onChange={handleChange}/>약국치료</label>
                    <label><input type="radio" name="c216" value="3" onChange={handleChange}/>병가, 산재</label>
                    <label><input type="radio" name="c216" value="4" onChange={handleChange}/>작업 전환</label>
                    <label><input type="radio" name="c216" value="5" onChange={handleChange}/>해당사항 없음</label>
                    <label><input type="radio" name="c216" value="6" onChange={handleChange}/>기타 <input type="text" name="c216other" onChange={handleChange}/></label>
                  </div>
                </div>
              </div>
            </div>
            {/* 어깨 */}
            <div style={c12.indexOf("2") !== -1 ? { display: "block" } : { display: "none" }}>
              <div style={{border : "1px solid gray", padding : "10px", marginBottom : "20px"}}>
                <p style={{fontWeight : 400}}>어깨</p>
                <div>
                  <p style={{fontWeight : 400}}>1. 통증의 구체적 부위는?</p>
                  <div>
                    <label><input type="radio" name="c221" value="1" onChange={handleChange}/>오른쪽</label>
                    <label><input type="radio" name="c221" value="2" onChange={handleChange}/>왼쪽</label>
                    <label><input type="radio" name="c221" value="3" onChange={handleChange}/>양쪽 모두</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>2. 한번 아프기 시작하면 통증 기간은 얼마 동안 지속됩니까?</p>
                  <div>
                    <label><input type="radio" name="c222" value="1" onChange={handleChange}/>1일 미만</label>
                    <label><input type="radio" name="c222" value="2" onChange={handleChange}/>1일 ~ 1주일 미만</label>
                    <label><input type="radio" name="c222" value="3" onChange={handleChange}/>1주일 ~ 1달 미만</label>
                    <label><input type="radio" name="c222" value="4" onChange={handleChange}/>1달 ~ 6개월 미만</label>
                    <label><input type="radio" name="c222" value="5" onChange={handleChange}/>6개월 이상</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>3. 그때의 아픈 정도는 어느 정도 입니까? </p>
                  <div>
                    <label><input type="radio" name="c223" value="1" onChange={handleChange}/>약한 통증</label>
                    <label><input type="radio" name="c223" value="2" onChange={handleChange}/>중간 통증</label>
                    <label><input type="radio" name="c223" value="3" onChange={handleChange}/>심한 통증</label>
                    <label><input type="radio" name="c223" value="4" onChange={handleChange}/>매우 심한 통증</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>4. 지난 1년 동안 이러한 증상을 얼마나 자주 경험하셨습니까? </p>
                  <div>
                    <label><input type="radio" name="c224" value="1" onChange={handleChange}/>6개월에 1번</label>
                    <label><input type="radio" name="c224" value="2" onChange={handleChange}/>2~3달에 1번</label>
                    <label><input type="radio" name="c224" value="3" onChange={handleChange}/>1달에 1번</label>
                    <label><input type="radio" name="c224" value="4" onChange={handleChange}/>매일</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>5. 지난 1주일 동안에도 이러한 증상이 있었습니까?</p>
                  <div>
                    <label><input type="radio" name="c225" value="1" onChange={handleChange}/>아니요</label>
                    <label><input type="radio" name="c225" value="2" onChange={handleChange}/>예</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>6. 지난 1년 동안 이러한 통증으로 인해 어떤 일이 있었습니까?</p>
                  <div>
                    <label><input type="radio" name="c226" value="1" onChange={handleChange}/>병원, 한의원 치료</label>
                    <label><input type="radio" name="c226" value="2" onChange={handleChange}/>약국치료</label>
                    <label><input type="radio" name="c226" value="3" onChange={handleChange}/>병가, 산재</label>
                    <label><input type="radio" name="c226" value="4" onChange={handleChange}/>작업 전환</label>
                    <label><input type="radio" name="c226" value="5" onChange={handleChange}/>해당사항 없음</label>
                    <label><input type="radio" name="c226" value="6" onChange={handleChange}/>기타 <input type="text" name="c226other" onChange={handleChange}/></label>

                  </div>
                </div>
              </div>
            </div>
            {/* 팔/팔꿈치 */}
            <div style={c12.indexOf("3") !== -1 ? { display: "block" } : { display: "none" }}>
              <div style={{border : "1px solid gray", padding : "10px", marginBottom : "20px"}}>
                <p style={{fontWeight : 400}}>팔/팔꿈치</p>
                <div>
                  <p style={{fontWeight : 400}}>1. 통증의 구체적 부위는?</p>
                  <div>
                    <label><input type="radio" name="c231" value="1" onChange={handleChange}/>오른쪽</label>
                    <label><input type="radio" name="c231" value="2" onChange={handleChange}/>왼쪽</label>
                    <label><input type="radio" name="c231" value="3" onChange={handleChange}/>양쪽 모두</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>2. 한번 아프기 시작하면 통증 기간은 얼마 동안 지속됩니까?</p>
                  <div>
                    <label><input type="radio" name="c232" value="1" onChange={handleChange}/>1일 미만</label>
                    <label><input type="radio" name="c232" value="2" onChange={handleChange}/>1일 ~ 1주일 미만</label>
                    <label><input type="radio" name="c232" value="3" onChange={handleChange}/>1주일 ~ 1달 미만</label>
                    <label><input type="radio" name="c232" value="4" onChange={handleChange}/>1달 ~ 6개월 미만</label>
                    <label><input type="radio" name="c232" value="5" onChange={handleChange}/>6개월 이상</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>3. 그때의 아픈 정도는 어느 정도 입니까? </p>
                  <div>
                    <label><input type="radio" name="c233" value="1" onChange={handleChange}/>약한 통증</label>
                    <label><input type="radio" name="c233" value="2" onChange={handleChange}/>중간 통증</label>
                    <label><input type="radio" name="c233" value="3" onChange={handleChange}/>심한 통증</label>
                    <label><input type="radio" name="c233" value="4" onChange={handleChange}/>매우 심한 통증</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>4. 지난 1년 동안 이러한 증상을 얼마나 자주 경험하셨습니까? </p>
                  <div>
                    <label><input type="radio" name="c234" value="1" onChange={handleChange}/>6개월에 1번</label>
                    <label><input type="radio" name="c234" value="2" onChange={handleChange}/>2~3달에 1번</label>
                    <label><input type="radio" name="c234" value="3" onChange={handleChange}/>1달에 1번</label>
                    <label><input type="radio" name="c234" value="4" onChange={handleChange}/>매일</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>5. 지난 1주일 동안에도 이러한 증상이 있었습니까?</p>
                  <div>
                    <label><input type="radio" name="c235" value="1" onChange={handleChange}/>아니요</label>
                    <label><input type="radio" name="c235" value="2" onChange={handleChange}/>예</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>6. 지난 1년 동안 이러한 통증으로 인해 어떤 일이 있었습니까?</p>
                  <div>
                    <label><input type="radio" name="c236" value="1" onChange={handleChange}/>병원, 한의원 치료</label>
                    <label><input type="radio" name="c236" value="2" onChange={handleChange}/>약국치료</label>
                    <label><input type="radio" name="c236" value="3" onChange={handleChange}/>병가, 산재</label>
                    <label><input type="radio" name="c236" value="4" onChange={handleChange}/>작업 전환</label>
                    <label><input type="radio" name="c236" value="5" onChange={handleChange}/>해당사항 없음</label>
                    <label><input type="radio" name="c236" value="6" onChange={handleChange}/>기타 <input type="text" name="c236other" onChange={handleChange}/></label>
                  </div>
                </div>
              </div>
            </div>
            {/* 손/손목/손가락 */}
            <div style={c12.indexOf("4") !== -1 ? { display: "block" } : { display: "none" }}>
              <div style={{border : "1px solid gray", padding : "10px", marginBottom : "20px"}}>
                <p style={{fontWeight : 400}}>손/손목/손가락</p>
                <div>
                  <p style={{fontWeight : 400}}>1. 통증의 구체적 부위는?</p>
                  <div>
                    <label><input type="radio" name="c241" value="1" onChange={handleChange}/>오른쪽</label>
                    <label><input type="radio" name="c241" value="2" onChange={handleChange}/>왼쪽</label>
                    <label><input type="radio" name="c241" value="3" onChange={handleChange}/>양쪽 모두</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>2. 한번 아프기 시작하면 통증 기간은 얼마 동안 지속됩니까?</p>
                  <div>
                    <label><input type="radio" name="c242" value="1" onChange={handleChange}/>1일 미만</label>
                    <label><input type="radio" name="c242" value="2" onChange={handleChange}/>1일 ~ 1주일 미만</label>
                    <label><input type="radio" name="c242" value="3" onChange={handleChange}/>1주일 ~ 1달 미만</label>
                    <label><input type="radio" name="c242" value="4" onChange={handleChange}/>1달 ~ 6개월 미만</label>
                    <label><input type="radio" name="c242" value="5" onChange={handleChange}/>6개월 이상</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>3. 그때의 아픈 정도는 어느 정도 입니까? </p>
                  <div>
                    <label><input type="radio" name="c243" value="1" onChange={handleChange}/>약한 통증</label>
                    <label><input type="radio" name="c243" value="2" onChange={handleChange}/>중간 통증</label>
                    <label><input type="radio" name="c243" value="3" onChange={handleChange}/>심한 통증</label>
                    <label><input type="radio" name="c243" value="4" onChange={handleChange}/>매우 심한 통증</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>4. 지난 1년 동안 이러한 증상을 얼마나 자주 경험하셨습니까? </p>
                  <div>
                    <label><input type="radio" name="c244" value="1" onChange={handleChange}/>6개월에 1번</label>
                    <label><input type="radio" name="c244" value="2" onChange={handleChange}/>2~3달에 1번</label>
                    <label><input type="radio" name="c244" value="3" onChange={handleChange}/>1달에 1번</label>
                    <label><input type="radio" name="c244" value="4" onChange={handleChange}/>매일</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>5. 지난 1주일 동안에도 이러한 증상이 있었습니까?</p>
                  <div>
                    <label><input type="radio" name="c245" value="1" onChange={handleChange}/>아니요</label>
                    <label><input type="radio" name="c245" value="2" onChange={handleChange}/>예</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>6. 지난 1년 동안 이러한 통증으로 인해 어떤 일이 있었습니까?</p>
                  <div>
                    <label><input type="radio" name="c246" value="1" onChange={handleChange}/>병원, 한의원 치료</label>
                    <label><input type="radio" name="c246" value="2" onChange={handleChange}/>약국치료</label>
                    <label><input type="radio" name="c246" value="3" onChange={handleChange}/>병가, 산재</label>
                    <label><input type="radio" name="c246" value="4" onChange={handleChange}/>작업 전환</label>
                    <label><input type="radio" name="c246" value="5" onChange={handleChange}/>해당사항 없음</label>
                    <label><input type="radio" name="c246" value="6" onChange={handleChange}/>기타 <input type="text" name="c246other" onChange={handleChange}/></label>
                  </div>
                </div>
              </div>
            </div>
            {/* 허리 */}
            <div style={c12.indexOf("5") !== -1 ? { display: "block" } : { display: "none" }}>
              <div style={{border : "1px solid gray", padding : "10px", marginBottom : "20px"}}>
                <p style={{fontWeight : 400}}>허리</p>
                <div>
                  <p style={{fontWeight : 400}}>2. 한번 아프기 시작하면 통증 기간은 얼마 동안 지속됩니까?</p>
                  <div>
                    <label><input type="radio" name="c252" value="1" onChange={handleChange}/>1일 미만</label>
                    <label><input type="radio" name="c252" value="2" onChange={handleChange}/>1일 ~ 1주일 미만</label>
                    <label><input type="radio" name="c252" value="3" onChange={handleChange}/>1주일 ~ 1달 미만</label>
                    <label><input type="radio" name="c252" value="4" onChange={handleChange}/>1달 ~ 6개월 미만</label>
                    <label><input type="radio" name="c252" value="5" onChange={handleChange}/>6개월 이상</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>3. 그때의 아픈 정도는 어느 정도 입니까? </p>
                  <div>
                    <label><input type="radio" name="c253" value="1" onChange={handleChange}/>약한 통증</label>
                    <label><input type="radio" name="c253" value="2" onChange={handleChange}/>중간 통증</label>
                    <label><input type="radio" name="c253" value="3" onChange={handleChange}/>심한 통증</label>
                    <label><input type="radio" name="c253" value="4" onChange={handleChange}/>매우 심한 통증</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>4. 지난 1년 동안 이러한 증상을 얼마나 자주 경험하셨습니까? </p>
                  <div>
                    <label><input type="radio" name="c254" value="1" onChange={handleChange}/>6개월에 1번</label>
                    <label><input type="radio" name="c254" value="2" onChange={handleChange}/>2~3달에 1번</label>
                    <label><input type="radio" name="c254" value="3" onChange={handleChange}/>1달에 1번</label>
                    <label><input type="radio" name="c254" value="4" onChange={handleChange}/>매일</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>5. 지난 1주일 동안에도 이러한 증상이 있었습니까?</p>
                  <div>
                    <label><input type="radio" name="c255" value="1" onChange={handleChange}/>아니요</label>
                    <label><input type="radio" name="c255" value="2" onChange={handleChange}/>예</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>6. 지난 1년 동안 이러한 통증으로 인해 어떤 일이 있었습니까?</p>
                  <div>
                    <label><input type="radio" name="c256" value="1" onChange={handleChange}/>병원, 한의원 치료</label>
                    <label><input type="radio" name="c256" value="2" onChange={handleChange}/>약국치료</label>
                    <label><input type="radio" name="c256" value="3" onChange={handleChange}/>병가, 산재</label>
                    <label><input type="radio" name="c256" value="4" onChange={handleChange}/>작업 전환</label>
                    <label><input type="radio" name="c256" value="5" onChange={handleChange}/>해당사항 없음</label>
                    <label><input type="radio" name="c256" value="6" onChange={handleChange}/>기타 <input type="text" name="c256other" onChange={handleChange}/></label>
                  </div>
                </div>
              </div>
            </div>
            {/* 다리/발 */}
            <div style={c12.indexOf("6") !== -1 ? { display: "block" } : { display: "none" }}>
              <div style={{border : "1px solid gray", padding : "10px", marginBottom : "20px"}}>
                <p style={{fontWeight : 400}}>다리/발</p>
                <div>
                  <p style={{fontWeight : 400}}>1. 통증의 구체적 부위는?</p>
                  <div>
                    <label><input type="radio" name="c261" value="1" onChange={handleChange}/>오른쪽</label>
                    <label><input type="radio" name="c261" value="2" onChange={handleChange}/>왼쪽</label>
                    <label><input type="radio" name="c261" value="3" onChange={handleChange}/>양쪽 모두</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>2. 한번 아프기 시작하면 통증 기간은 얼마 동안 지속됩니까?</p>
                  <div>
                    <label><input type="radio" name="c262" value="1" onChange={handleChange}/>1일 미만</label>
                    <label><input type="radio" name="c262" value="2" onChange={handleChange}/>1일 ~ 1주일 미만</label>
                    <label><input type="radio" name="c262" value="3" onChange={handleChange}/>1주일 ~ 1달 미만</label>
                    <label><input type="radio" name="c262" value="4" onChange={handleChange}/>1달 ~ 6개월 미만</label>
                    <label><input type="radio" name="c262" value="5" onChange={handleChange}/>6개월 이상</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>3. 그때의 아픈 정도는 어느 정도 입니까? </p>
                  <div>
                    <label><input type="radio" name="c263" value="1" onChange={handleChange}/>약한 통증</label>
                    <label><input type="radio" name="c263" value="2" onChange={handleChange}/>중간 통증</label>
                    <label><input type="radio" name="c263" value="3" onChange={handleChange}/>심한 통증</label>
                    <label><input type="radio" name="c263" value="4" onChange={handleChange}/>매우 심한 통증</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>4. 지난 1년 동안 이러한 증상을 얼마나 자주 경험하셨습니까? </p>
                  <div>
                    <label><input type="radio" name="c264" value="1" onChange={handleChange}/>6개월에 1번</label>
                    <label><input type="radio" name="c264" value="2" onChange={handleChange}/>2~3달에 1번</label>
                    <label><input type="radio" name="c264" value="3" onChange={handleChange}/>1달에 1번</label>
                    <label><input type="radio" name="c264" value="4" onChange={handleChange}/>매일</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>5. 지난 1주일 동안에도 이러한 증상이 있었습니까?</p>
                  <div>
                    <label><input type="radio" name="c265" value="1" onChange={handleChange}/>아니요</label>
                    <label><input type="radio" name="c265" value="2" onChange={handleChange}/>예</label>
                  </div>
                  <hr/>
                  <p style={{fontWeight : 400}}>6. 지난 1년 동안 이러한 통증으로 인해 어떤 일이 있었습니까?</p>
                  <div>
                    <label><input type="radio" name="c266" value="1" onChange={handleChange}/>병원, 한의원 치료</label>
                    <label><input type="radio" name="c266" value="2" onChange={handleChange}/>약국치료</label>
                    <label><input type="radio" name="c266" value="3" onChange={handleChange}/>병가, 산재</label>
                    <label><input type="radio" name="c266" value="4" onChange={handleChange}/>작업 전환</label>
                    <label><input type="radio" name="c266" value="5" onChange={handleChange}/>해당사항 없음</label>
                    <label><input type="radio" name="c266" value="6" onChange={handleChange}/>기타 <input type="text" name="c266other" onChange={handleChange}/></label>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div style={{marginBottom : "20px"}}>
              <Button onClick={handleSave}>제출하기</Button>
            </div>


          </div>
        </div>
        </form>

      </div>
    </div>
  )
}

const check_value = (inputs) =>{
  const {agree, a1, a2, a3, a4, a42, a5, a52, a53, a6, a7, a72, a73, a8,a82, a83, a9,a92,a93,
    b1, b2, b3, b32, b321, b322, b323, b324, b325, b4, b42, b5,
    c1, c12,
    c212, c213, c214, c215, c216, c216other,
    c221, c222, c223, c224, c225, c226, c226other,
    c231, c232, c233, c234, c235, c236, c236other,
    c241, c242, c243, c244, c245, c246, c246other,
    c252, c253, c254, c255, c256, c256other,
    c261, c262, c263, c264, c265, c266, c266other, } = inputs;
  if (agree !== "1") {
    alert("개인정보 수집에 동의해야합니다.")
    return true;
  }
  if (a1 === "") {
    alert("이름을 입력해주세요.")
    return true;
  }
  if (a2 === "") {
    alert("나이를 입력하세요.");
    return true;
  }

  if (a3 === "0") {
    alert("성별을 선택하세요.")
    return true;
  }
  if (a4 == "" && a42 == "") {
    alert("현 직장경력을 입력하세요")
    return true;
  }
  if (a4 == "0" && a42 == "0") {
    alert("현 직장경력을 입력하세요. 현재 0년 0개월")
    return true;
  }
  if (a5 === "") {
    alert("작업부서를 입력하세요.")
    return true;
  }
  if (a5 === "16" && a52 === "") {
    alert("작업부서 기타항목을 입력하세요.");
    return true;
  }

  if (a6 === "0") {
    alert("결혼여부를 선택하세요.")
    return true;
  }
  if (a7 === "") {
    alert("현재 하시는 작업내용을 입력하세요.")
    return true;
  }
  if (a72 == "" && a73 == "") {
    alert("현재 하시는 작업 기간과 개월을 입력하세요.");
    return;
  }
  if (a72 == "0" && a73 == "0") {
    alert("현재 하시는 작업 기간과 개월을 입력하세요. 현재 0년 0개월 ");
    return;
  }
  if (a8 === "" || a82 === "" || a83 === "") {
    alert("1일 근무시간과 휴식 시간을 입력하세요.")
    return true;
  }
  if (b1.length === 0 ) {
    alert("취미활동을 선택하세요.")
    return true;
  }
  if (b2 === "0" ) {
    alert("평균 가사노동시간을 선택하세요.")
    return true;
  }
  if (b3 === "0" ) {
    alert("진단여부를 선택하세요.")
    return true;
  }
  if (b3 === "2") {
    if (b32.length === 0) {
      alert("진단받은 적이 있는 병명을 선택하세요.");
      return true;
    }else {
      if (b32.indexOf("1") !== -1) {
        if (b321 === "0") {
          alert("류머티스 관절염에 대한 상태를 선택하세요");
          return true;
        }
      }
      if (b32.indexOf("2") !== -1) {
        if (b322 === "0") {
          alert("당뇨병에 대한 상태를 선택하세요");
          return true;
        }

      }
      if (b32.indexOf("3") !== -1) {
        if (b323 === "0") {
          alert("루프스병에 대한 상태를 선택하세요");
          return true;
        }

      }
      if (b32.indexOf("4") !== -1) {
        if (b324 === "0") {
          alert("통풍에 대한 상태를 선택하세요");
          return true;
        }

      }
      if (b32.indexOf("5") !== -1) {
        if (b325 === "0") {
          alert("알코올 중독에 대한 상태를 선택하세요");
          return true;
        }

      }
    }
  }
  if (b4 === "0") {
    alert("과거의 다친 여부를 선택하세요.")
    return true;
  }
  if (b4 === "2") {
    if (b42.length === 0) {
      alert("다친 부위를 선택하세요.")
      return true;
    }
  }
  if (b5 === "0" ) {
    alert("노동 강도를 선택하세요.")
    return true;
  }
  if (c1 === "0") {
    alert("지난 1년동안 해당 부위의 통증 여부에 대해 선택하세요,");
    return true;
  }
  if (c1 === "1") {
    return false;
  }
  if (c1 === "2") {
    if (c12.length === 0) {
      alert("통증 부위를 선택하세요")
      return true;
    }else {
      if (c12.indexOf("1") !== -1) {
        if (c212 === "0" || c213 === "0" || c214 === "0" || c215 === "0" || c216 === "0") {
          alert("목 상태를 입력하세요.");
          return true;
        }
        if (c216 === "6" && c216other === "") {
          alert("목 상태의 지난 1년간 통증의 기타를 입력하세요");
          return true;
        }
      }
      if (c12.indexOf("2") !== -1) {
        if (c221 === "0" || c222 === "0" || c223 === "0" || c224 === "0" || c225 === "0" || c226 === "0") {
          alert("어깨 상태를 입력하세요.");
          return true;
        }
        if (c226 === "6" && c226other === "") {
          alert("어깨 상태의 지난 1년간 통증의 기타를 입력하세요");
          return true;
        }
      }
      if (c12.indexOf("3") !== -1) {
        if (c231 === "0" || c232 === "0" || c233 === "0" || c234 === "0" || c235 === "0" || c236 === "0") {
          alert("팔/팔꿈치 상태를 입력하세요.");
          return true;
        }
        if (c236 === "6" && c236other === "") {
          alert("팔/팔꿈치 상태의 지난 1년간 통증의 기타를 입력하세요");
          return true;
        }
      }
      if (c12.indexOf("4") !== -1) {
        if (c241 === "0" || c242 === "0" || c243 === "0" || c244 === "0" || c245 === "0" || c246 === "0") {
          alert("손/손목/손가락 상태를 입력하세요.");
          return true;
        }
        if (c246 === "6" && c246other === "") {
          alert("손/손목 상태의 지난 1년간 통증의 기타를 입력하세요");
          return true;
        }
      }
      if (c12.indexOf("5") !== -1) {
        if (c252 === "0" || c253 === "0" || c254 === "0" || c255 === "0" || c256 === "0") {
          alert("허리 상태를 입력하세요.");
          return true;
        }
        if (c256 === "6" && c256other === "") {
          alert("허리 상태의 지난 1년간 통증의 기타를 입력하세요");
          return true;
        }
      }
      if (c12.indexOf("6") !== -1) {
        if (c261 === "0" || c262 === "0" || c263 === "0" || c264 === "0" || c265 === "0" || c266 === "0") {
          alert("다리/발 상태를 입력하세요.");
          return true;
        }
        if (c266 === "6" && c266other === "") {
          alert("다리/발 상태의 지난 1년간 통증의 기타를 입력하세요");
          return true;
        }
      }
    }

  }
  return false;


}
export default Research;