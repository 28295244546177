import React, {useState, useEffect} from "react";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import Editor from './EditorComponent'
import axios from 'axios'
import {useHistory} from "react-router-dom";
const ReferenceEdit = ({match}) => {
    const server = "http://18.179.138.213:3001"
    const [checked, setChecked] = useState(false)
    const [files, setFiles] = useState({})
    const [oldfiles, setOldfiles] = useState([])
    const [desc, setDesc] = useState("");
    const [orgFiles, setOrgFiles] = useState([])
    const [inputs, setInputs] = useState({
    "title" : "",
    "category" : "",
        "date" : ""
  })
    const history = useHistory()

    let id = match.params.id
    useEffect(() => {

       axios.get(server + "/reference-view/" + id, {
        headers: { 'Content-Type' : 'application/json'}
       }).then((response) => {
            let datas = response.data;
            if(datas.code == 200) {
                setInputs({
                      "title" : datas['data']['title'],
                      "category" : datas['data']['category'],
                      "date" : datas['data']['date']
                })
                setOldfiles(datas['data']['files'].split(","))
                setDesc(datas['data']['content'])
                setChecked(datas['data']['notice'])
                setOrgFiles(datas['data']['orgFiles'].split(","))

            }else {
                alert("게시글을 불러오는데 실패하였습니다.")
                history.push("/references/construction")
            }
        })
      }, [])
    const {title, category, date} = inputs
    const handleSave = async() => {
        if(title === '') { return alert('제목을 입력해주세요')}
        if(desc === '') { return alert('내용을 입력해주세요')}
        if(category === '') {return alert('카테고리를 선택해주세요')}
        if(date === '') {return alert('발행날짜를 선택해주세요')}
        let formData = new FormData()
        let username = sessionStorage.getItem('username')
        let token = sessionStorage.getItem('token')

        let post_oldfiles = oldfiles.join(",")
        let post_oldOrgFiles = orgFiles.join(",")


        for (let i=0; i< files.length; i++) {
          formData.append(`files`, files[i])
        }
        formData.append('date', date)
        formData.append('title', title)
        formData.append('content', desc)
        formData.append('category', category)
        formData.append('notice', checked)
        formData.append('oldfiles', post_oldfiles)
        formData.append('oldOrgFiles', post_oldOrgFiles);

        await axios.put(
            server + "/admin/reference-edit/" + id, formData,{
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': token
              }}
          ).then((response) => {
            let datas = response.data
            if (datas.code == 200){
              alert("저장되었습니다.")
              history.push("/reference/" + datas.data)
            } else {
              alert("저장에 실패하였습니다.")
            }
          }).catch((err) => {
            alert("권한이 없습니다.")
          })

      }
    const handleCategory = (e) => {
        setInputs({
            ...inputs,
            "category" : e.target.value
        })
    }
    const onEditorChange = (value) => {
        setDesc(value)

    }
    const handleChange = (e) => {
        let {value, name} = e.target

        setInputs({
          ...inputs,
          [name] : value
        })
      }
    const handleFile = (e) => {
    let t_files = e.target.files
        setFiles(t_files)
    }
    const handleRemove = (index) => {
        let newList = oldfiles.filter((date, idx) => idx !== index)
        setOldfiles(newList);

        let newList2 = orgFiles.filter((date, idx) => idx !== index)
        setOrgFiles(newList2);
      }

      const handleDelete = () => {
        let token = sessionStorage.getItem('token')
        axios.delete(server + "/admin/reference/" + id,{
          headers: {
            'Authorization': token
          }}).then(response => {
            let datas = response.data
            if (datas['code'] == 200){
              alert("삭제되었습니다.")
              history.push("/reference")
            }
          }).catch((err) => {
            alert("삭제하실 수 없습니다.")
          })
      }
    return (
        <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header className="d-flex" style={{justifyContent : "space-between"}}>
                <Card.Title as="h4">게시글 수정</Card.Title>
                <div>
                 <Button
                    variant="danger"
                    size="sm"
                    onClick={handleDelete}
                    style={{marginRight : "10px"}}
                  >삭제</Button>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={handleSave}
                  >저장</Button>
                </div>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pl-1" md="12">
                      <Form.Group>
                        <label>제목</label>
                        <Form.Control
                          placeholder="Company"
                          type="text"
                          name="title"
                          value={title}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>카테고리</label>
                        <Form.Control as="select" value={category} onChange={handleCategory}>
                          <option name="construction" value="construction">건설업</option>
                          <option name="manufacture" value="manufacture">제조업</option>
                          <option name="example" value="example">중대재해사례</option>
                          <option name="safety" value="safety">안전진단</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>공지여부</label>
                        <Form.Control
                          type="checkbox"
                          name="checked"
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>게시일</label>
                        <Form.Control
                          type="date"
                          name="date"
                          value={date}
                          placeholder="2020-01-01"
                                    onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-1" md="12">
                      <Form.Group>
                        <label>기존파일</label>
                       <div style={{overflow : "auto", width : "90%", paddingLeft : "10px", display : "block"}}>
                                    { oldfiles.length === 0 ? <h4>등록된 파일이 없습니다.</h4>
                                    : oldfiles.map((file, index) => {
                                        return <div key={index}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash"
                       viewBox="0 0 16 16" onClick={()=>handleRemove(index)}>
                    <path
                      d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fillRule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                                            <a href={file} >{ orgFiles[index] } <br/></a>
                                        </div>
                                        })}
                       </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-1" md="12">
                      <Form.Group>
                        <label>파일</label>
                        <Form.Control
                          type="file"
                          name="files"
                          multiple
                          onChange={handleFile}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="12">
                      <Editor value={desc|| ""} onChange={onEditorChange} />
                    </Col>
                  </Row>


                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
    </>
    )
}
export default ReferenceEdit;
