import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import bg from "../../assets/img/bg3.jpg"
import axios from 'axios'
const Contact = () => {
  //const server = "http://18.179.138.213:3001";
  const server = "http://18.179.138.213:3001";
  const [inputs, setInputs] = useState({
    "title" : "",
    "name" : "",
    "password" : "",
    "content" : "",
    "email" : "",
    "phone" : ""
  });

  const { title, name, password, content, email, phone } = inputs;

  const handleChange = (e) => {
    e.preventDefault();
    let {value, name} = e.target
    setInputs({
      ...inputs,
      [name] : value
    })
  }
  const handleSave = async () => {
    if (title === "") {
      return alert("제목을 입력하여주십시오.")
    }

    if (name === "") {
      return alert("성함을 입력하여주십시오.")
    }

    if (content === "") {
      return alert("문의내용을 입력하여주십시오.")
    }

    if (phone === "" && email === "") {
      alert("연락처 또는 이메일을 입력하여주십시오.");
      return ;
    }

    if (password === "") {
      return alert("비밀번호를 입력하여주십시오.")
    }

      await axios.post(
        server + "/contact", inputs
      ).then((response) => {
        let datas = response.data
        if (response.status == 200){
          alert("문의주셔서 감사합니다. 빠른 시일내에 답변드리겠습니다.")
          window.location.replace("/support");


        }
      }).catch((err) => {
      })
    }
    return (
        <div className="sub_container">
        {/*-- 타이틀 --*/}
        <div className="sub_title_banner" style={{background: `url(${bg}) center center no-repeat`}}>
          <h2 className="tit">Q&A</h2>
        </div>
        {/*--// 타이틀 --*/}
        {/*-- 리본바 --*/}
        <div className="sub_reborn">
          <ul>
            <li><Link to="/">홈</Link></li>
            <li><Link to="/contact">Q&A</Link></li>
          </ul>
        </div>
        {/*--// 리본바 --*/}
        <div className="sub_conbox ask">
          <div className="infobox">
            <ul>
              <li>
                <span className="tit">TEL</span>
                <span>031-405-7833</span>
              </li>
              <li>
                <span className="tit">FAX</span>
                <span>031-405-7835</span>
              </li>
              <li>
                <span className="tit">Email</span>
                <span>4057833@hanmail.net</span>
              </li>
            </ul>
          </div>
          <div className="askbox">
            <h2>Q&A</h2>
            <p>
              방문해주셔서 감사합니다.<br />
              궁금하신점은 언제든지 문의부탁드립니다.
            </p>
            <div className="form">
              <ul>
                <li>
                  <label htmlFor="f1">이름</label>
                  <input id="f1" type="text" className="ask_input" placeholder="OOO"
                    name="name"
                        value={name}
                        onChange={handleChange}
                  />
                </li>
                <li>
                  <label htmlFor="f2">이메일</label>
                  <input id="f2" type="text" className="ask_input" placeholder="email@email.com"
                    name="email"
                        value={email}
                        onChange={handleChange}
                  />
                </li>
                <li>
                  <label htmlFor="f3">연락처</label>
                  <input id="f3" type="text" className="ask_input" placeholder="010-****-****"
                  name="phone"
                        value={phone}
                        onChange={handleChange}
                  />
                </li>
                <li>
                  <label htmlFor="f3">제목</label>
                  <input id="f3" type="text" className="ask_input" placeholder="기술지도계약 문의."
                  name="title"
                        value={title}
                        onChange={handleChange}
                  />
                </li>
              </ul>
              <label htmlFor="f4">문의내용</label>
              <textarea id="f4" cols={30} rows={8} placeholder="무엇이 궁금하신가요?" style={{border : "1px solid black"}}
                        name="content"
                        value={content}
                        onChange={handleChange}/>

              <ul>
                <li>
                  <label htmlFor="f3">비밀번호</label>
                  <input id="f3" type="text" className="ask_input" placeholder=""
                  name="password"
                        value={password}
                        onChange={handleChange}
                  />
                </li>
              </ul>
            </div>
            <button type="button" className="ask_btn" onClick={handleSave}>보내기</button>
          </div>
        </div>
      </div>
    )
}
export default Contact;
