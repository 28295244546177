import React from 'react'
import {Link} from "react-router-dom";
import "./history.css"
import constructionok from "../../../assets/img/constructionok.png";
import safetyok from "../../../assets/img/safeok.png";
import safetyok2 from "../../../assets/img/safetyok2.jpg";
export default function History() {

  return (
    <div className="sub_container">
        {/*-- 타이틀 --*/}
        <div className="sub_title_banner">
          <h2 className="tit">연혁</h2>
        </div>
        {/*--// 타이틀 --*/}
        {/*-- 리본바 --*/}
        <div className="sub_reborn">
          <ul>
            <li><Link to="/">홈</Link></li>
            <li><Link to="/intro">회사소개</Link></li>
            <li><Link to="/intro/history">연혁</Link></li>
          </ul>
        </div>
        {/*--// 리본바 --*/}
        <div className="history-container"  >
            <h3 style={{fontWeight : "bold"}}>회사 연혁</h3>
            <hr/>
            <div className="history">
              <div>
                <h2>2024</h2>
                <ol>
                  <li>1. 민간위탁사업(건설분야)기관평가 S등급 선정</li>
                  <li>2. 재해예방전문지도기관 기관평가 A등급 선정</li>
                </ol>
              </div>

              <div>
                <h2>2023</h2>
                <ol>
                  <li>1. 민간위탁사업(건설분야)기관평가 A등급 선정</li>
                  <li>2. 재해예방전문지도기관 기관평가 B등급 선정</li>
                </ol>
              </div>

              <div>
                <h2>2022</h2>
                <ol>
                  <li>1. 안전관리전문지도기관 기관평가 A등급 선정</li>
                  <li>2. 재해예방전문지도기관 기관평가 A등급 선정</li>

                </ol>
              </div>
              <div>
                <h2>2021</h2>
                <ol>
                  <li>1. 민간위탁사업(건설분야)기관평가 A등급 선정</li>
                  <li>2. 안전관리전문지도기관 기관평가 A등급 선정</li>
                  <li>3. 재해예방전문지도기관 기관평가 B등급 선정</li>
                </ol>
              </div>
              <div>
                <h2>2020</h2>
                <ol>
                  <li>1. 한국산업안전보건공단 우수지도요원상 수상 (김병록)</li>
                  <li>2. 민간위탁사업(건설분야)기관평가 S등급 선정</li>
                  <li>3. 안전관리전문지도기관 기관평가 A등급 선정</li>
                  <li>4. 재해예방전문지도기관 기관평가 C등급 선정</li>
                  <li>5. (주)한국작업환경연구원 MOU협약</li>
                </ol>
              </div>
              <div>
                <h2>2019</h2>
                <ol>
                  <li>1. 의정부고용노동청 산업안전보건 거버넌스 <br/>[경기북부건설재해예방기술지도기관협의회(건설안전IF) 위원 위촉 (곽재만)]</li>
                </ol>
              </div>
              <div>
                <h2>2018</h2>
                <ol>
                  <li>1. (주)단원산업보건센터 MOU협약</li>
                  <li>2. (주)단원산업보건센타 MOU협약</li>
                </ol>
              </div>
              <div>
                <h2>2013</h2>
                <ol>
                  <li>1. 대림대학교 가족회사 협정</li>
                </ol>
              </div>
              <div>
                <h2>2011</h2>
                <ol>
                  <li>1. 국토교통부지정 안전진단 전문기관 지정</li>
                </ol>
              </div>
              <div>
                <h2>2010년 이전</h2>
                <ol>
                  <li>1. 노동부지정 안전관리전문기관 지정 (2010)</li>
                  <li>2. 노동부지정 재해예방전문지도기관 지정 (2009)</li>
                  <li>3. 한국재해예방관리원(주) 설립 (2009)</li>
                </ol>
              </div>
            </div>
          <div style={{margin : "40px 0px"}}>
                <h3 style={{fontWeight : "bold"}}>지정서 및 등록증</h3>
            <hr/>
                <table className="business_table2" style={{width : "100%", textAlign : "center", tableLayout : "fixed"}}>
                    <thead>
                    <tr>
                        <th><img style={{maxWidth : "100%", height : "400px"}} src={constructionok}/></th>
                        <th><img style={{maxWidth : "100%", height : "400px"}} src={safetyok}/></th>
                        <th><img style={{maxWidth : "100%", height : "400px"}} src={safetyok2}/></th>
                    </tr>
                    </thead>
                  <tbody>
                    <tr>
                        <td>건설재해예방전문지도기관 지정서</td>
                        <td>안전관리전문기관 지정서</td>
                        <td>안전진단전문기관 등록증</td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>

      </div>

  );
}
