import React, {useState, useEffect} from 'react';
import axios from 'axios'
import {Link, useHistory} from "react-router-dom";
import {Container, Row, Col, Card, Button, ListGroup, Form, Spinner} from "react-bootstrap";

const SupportView = ({match}) => {
    const server = "http://18.179.138.213:3001";
    const [loading, setLoading] = useState(false);
    const [isAuth, setIsAuth] = useState(0); // 인증 상태
    const [password, setPassword] = useState(''); // 비밀번호 입력 상태
    const [error, setError] = useState(''); // 비밀번호 오류 메시지
    const [contact, setContact] = useState({}); // 문의내용
    const [newComment, setNewComment] = useState(""); // 세댓글
    const [comments, setComments] = useState([]); // 댓들들

    let id = match.params.id;

    const history = useHistory();
    useEffect(() => {

        getAuth();
      }, []);

      const getAuth = () => {

        let token = sessionStorage.getItem('token');
        if (token == null || token == undefined || token == "") return;

        setLoading(true);
        axios.get(server + "/admin/contact/" + id,{
                headers: {
                'Authorization': token
                }}
        ).then((response) => {
                if (response.status == 200) {

                    if (response.data.data != null) {
                        setIsAuth(1);
                        setContact(response.data.data);
                        setComments(response.data.data.comments);
                    }
                }
                setLoading(false);
        }).catch((err) => {
            setLoading(false);
        });

    }

    const handleAuth = async (e) => {
        e.preventDefault();

        if (password == "") {
            alert("비밀번호를 입력하세요.");
            return ;
        }
        setLoading(true);
        await axios.post(
            server + "/contact/check", {"id" : id, "password" : password}
        ).then((response) => {
            if (response.status == 200) {
                if (response.data.data == null) {
                    setError("비밀번호를 정확히 입력해주십시오.");
                    return ;
                } else {
                    setIsAuth(2);
                    setContact(response.data.data);
                    setComments(response.data.data.comments);
                }
            } else {
                setError("비밀번호를 정확히 입력해주십시오.");
            }
            setLoading(false);
        }).catch((err)=> {
            setLoading(false);
        });


    };

    const handleAddComment = (e) => {
        e.preventDefault();
        if (newComment.trim() != "") {
            axios.post(
                server + "/contact/comment", {"content" : newComment, "name" : "관리자", "id" : contact.id}
            ).then((response) => {
                if (response.status == 200 && response.data.code == 200) {
                    alert("답변이 정상적으로 등록되었습니다.");
                    setComments([...comments, response.data.data]);
                    setNewComment("");
                }
            })
        }
    };

    const handleDelete = (e, id) => {
        e.preventDefault();

        if (confirm("정말 삭제하시겠습니까?")) {
            let token = sessionStorage.getItem('token')
            axios.delete(server + "/admin/comment/" + id,{
            headers: {
                'Authorization': token
            }}).then(response => {
                let datas = response.data
                if (datas['code'] == 200){
                    alert("삭제되었습니다.");

                    let newComments = comments.filter(data => data.id != id);
                    setComments(newComments);
                }
            }).catch((err) => {
                alert("삭제하실 수 없습니다.")
            })
        }
    }

    return (
          <div className="sub_container">
        {/*-- 타이틀 --*/}
        <div className="sub_title_banner">
          <h2 className="tit">Q&A</h2>
        </div>
        {/*--// 타이틀 --*/}
        {/*-- 리본바 --*/}
        <div className="sub_reborn">
          <ul>
            <li><Link to="/">홈</Link></li>
            <li><Link to="/support">Q&A</Link></li>
          </ul>
        </div>
        {/*--// 리본바 --*/}
        <div className="sub_conbox reperence">
        <Container className='mt-5'>
            <Row className="d-flex justify-content-center align-items-center">
            <Col md="6">
            {
                loading ?
                    <Container className='text-center'>
                    <Spinner animation="border" variant="primary"/>
                    <div className="mt-3">로딩중입니다.</div>
                    </Container>
                : isAuth == 0 ?
                            <Card>
                            <Card.Body>
                                <Card.Title style={{fontWeight : "600"}}>비밀 게시글을 보시려면 비밀번호를 입력하세요.</Card.Title>
                                <Form>
                                <Form.Group controlId="passwordForm">
                                    <Form.Label>비밀번호</Form.Label>
                                    <Form.Control
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="비밀번호를 입력하세요"
                                    />
                                    {error && <div className="text-danger mt-2">{error}</div>}
                                </Form.Group>
                                <Button variant="primary" onClick={handleAuth} size='sm'>
                                    인증
                                </Button>
                                </Form>
                            </Card.Body>
                            </Card>
                :
                <>
                    <Card className="mb-4">
                <Card.Header>
                  <h3>{contact.title}</h3>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <Card.Subtitle className="text-muted">작성자: {contact.name}</Card.Subtitle>
                    </Col>
                    <Col className="text-right">
                      <Card.Subtitle className="text-muted">{contact.date}</Card.Subtitle>
                    </Col>
                  </Row>
                  <hr />
                  <Card.Text className="mt-3">
                    {contact.content}
                  </Card.Text>
                </Card.Body>
              </Card>
              {/* 댓글 섹션 */}
              <Card className="mb-4">
                <Card.Header>
                  <h5>댓글 {comments && comments.length > 0 ? comments.length : "0"} 개</h5>
                </Card.Header>
                <Card.Body>
                  <ListGroup variant="flush">
                    {
                        comments && comments.length == 0 ?
                            <>
                            <ListGroup.Item>
                                등록된 답변이 없습니다.
                            </ListGroup.Item>
                            </>
                        :
                        comments && comments.map((data) => {
                            return (
                            <ListGroup.Item  key={data.id} className='d-flex justify-content-between align-items-center'>
                                <div>
                                <strong>{data.name}</strong>: {data.content}
                                </div>
                                {isAuth == 1 ? <div>
                                <Button variant='danger' onClick={(e) => handleDelete(e, data.id)} size="sm" className='text-danger'>
                                    삭제
                                </Button>
                                </div>
                                : ""
                                }
                            </ListGroup.Item>
                            )


                        })

                    }
                  </ListGroup>
                  <hr/>
                  {isAuth == 1 ?
                  <Form className="mt-3">
                    <Form.Group controlId="commentForm">
                      <Form.Label>댓글을 남기세요</Form.Label>
                      <Form.Control as="textarea" rows={5} placeholder="댓글을 입력하세요..." value={newComment} onChange={(e) => setNewComment(e.target.value)}/>
                      <Button variant="primary" className="mt-2" size="sm" onClick={(e) => handleAddComment(e)}>댓글 달기</Button>
                    </Form.Group>
                  </Form>
                : ""
                }
                </Card.Body>
              </Card>
                </>
                /*
                <Container>
                <Row>
                    <Col>
                        <h4 style={{fontWeight : "600"}}>{title}</h4>

                    </Col>
                </Row>
                <hr/>
                <Row>

                    <Col sm={2}>
                        <h5>작성자</h5>
                    </Col>

                    <Col sm={4}>
                        <h5>{writer}</h5>
                    </Col>
                    <Col sm={2}>
                        <h5>작성일</h5>
                    </Col>

                    <Col sm={4}>
                        <h5>{created_at}</h5>
                    </Col>

                </Row>
                <hr/>
                <Row>
                    <Col sm={2}>
                        <h5>첨부파일</h5>
                    </Col>
                    <Col>
                        <div style={{overflow : "auto"}}>
                                { files.length == 0 ? <h5>등록된 파일이 없습니다.</h5>
                                : files.map((file, index) => {
                                    return <div key={index}><i className="fas fa-save"></i><a href={file} target="_blank"> {orgFiles[index]} <br/></a></div>
                                    })}
                            </div>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col>
                        <div style={{overflow : "auto", padding : "10px"}} dangerouslySetInnerHTML={createMarkup()}></div>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col className="d-flex" style={{justifyContent : "space-between"}}>
                        <Button size="sm" variant="secondary" onClick={handleMenu}>목록으로</Button>
                        <Button size="sm" variant="secondary" onClick={() => {history.push("/admin/editreference/" + id)}}>수정</Button>
                    </Col>
                </Row>
            </Container>
            */
            }

                </Col>
            </Row>
        </Container>
        </div>
      </div>
      )
}
export default SupportView