import React from 'react';
import { Link } from 'react-router-dom'
const HomeHeader = ({location, history}) => {

      return (
          <div className="h_header_wrap">
          <header className="h_header">
            <h1 className="h_logo"><Link to="/" style={{fontWeight : "bold"}}>한국재해예방관리원㈜</Link></h1>
            <p className="h_toggle pc_skip tpc_skip">메뉴 열기</p>
            <p className="h_toggle_close pc_skip tpc_skip">메뉴 닫기</p>
            <nav className="h_nav">
              <ul className="h_level1">
                <li className="h_level1">
                  <p className="pc_skip tpc_skip">회사소개</p>
                  <Link to="/intro" className="m_skip">회사소개</Link>
                  <ul className="h_level2">
                    <li className="h_level2"><Link to="/intro">인사말</Link></li>
                    <li className="h_level2"><Link to ="/intro/history">연혁</Link></li>
                    <li className="h_level2"><Link to="/intro/organization">조직도</Link></li>
                    <li className="h_level2"><Link to="/intro/equipment">장비현황</Link></li>
                    <li className="h_level2"><Link to="/intro/manpower">인력현황</Link></li>
                  </ul>
                </li>
                <li className="h_level1">
                  <p className="pc_skip tpc_skip">사업분야</p>
                  <Link to="/business/technicalguidance" className="m_skip">사업분야</Link>
                  <ul className="h_level2">
                    <li className="h_level2">
                      <p className="pc_skip tpc_skip">건설/재해예방 기술지도</p>
                      <Link to="/business/technicalguidance" className="m_skip">건설/재해예방 기술지도</Link>
                      <ul className="h_level3">
                        <li className="h_level3"><Link to="/business/technicalguidance">재해예방 기술지도</Link></li>
                        <li className="h_level3"><Link to="/business/constructionsafety">건설현장 안전컨설팅</Link></li>
                        <li className="h_level3"><Link to="/business/constructionhazard">유해위험방지계획서</Link></li>
                        <li className="h_level3"><Link to="/business/safetyplan">안전관리계획서</Link></li>
                        <li className="h_level3"><Link to="/business/safetypaper">안전보건대상</Link></li>
                      </ul>
                    </li>
                    <li className="h_level2">
                      <p className="pc_skip tpc_skip">제조/안전관리자 위탁</p>
                      <Link to="/business/safetymanagement" className="m_skip">제조/안전관리자 위탁</Link>
                      <ul className="h_level3">
                        <li className="h_level3"><Link to="/business/safetymanagement">안전관리자 위탁</Link></li>
                        <li className="h_level3"><Link to="/business/manufacturinghazard">유해위험방지계획서</Link></li>
                        <li className="h_level3"><Link to="/business/psm">공정안전보고서(PSM)</Link></li>
                        <li className="h_level3"><Link to="/business/safetyconsulting" >안전교육 컨설팅</Link></li>
                        <li className="h_level3"><Link to="/business/musculoskeletal">근골격계 유해요인 조사</Link></li>
                      </ul>
                    </li>
                    <li className="h_level2">
                      <p className="pc_skip tpc_skip">안전진단</p>
                      <Link to="/business/adjacent" className="m_skip">안전진단</Link>
                      <ul className="h_level3">
                        <li className="h_level3"><Link to="/business/adjacent">인접지 사전조사</Link></li>
                        <li className="h_level3"><Link to="/business/regular">제3종 시설물 정기점검</Link></li>
                        <li className="h_level3"><Link to="/business/safetycheck">시특법 안전점검</Link></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="h_level1">
                  <p className="pc_skip tpc_skip">자료실</p>
                  <Link to="/references" className="m_skip">자료실</Link>
                  <ul className="h_level2">
                    <li className="h_level2"><Link to="/references/construction">건설업</Link>
                    </li><li className="h_level2"><Link to="/references/manufacture">제조업</Link>
                    </li><li className="h_level2"><Link to="/references/example">중대재해사례</Link>
                    </li><li className="h_level2"><Link to="/references/safety">안전진단</Link>
                    </li>
                  </ul>
                </li>
                <li className="h_level1">
                  <p className="pc_skip tpc_skip"><Link to="/support" style={{fontWeight : "400"}}>Q&A</Link></p>
                  <Link to="/support" className="m_skip" >Q&A</Link>
                </li>

              </ul>
            </nav>
            <div className="top">
              <ul>
                <li><Link to="/admin/contracts" style={{fontWeight : "bold"}}>기술지도계약서 작성</Link></li>
              </ul>
            </div>
          </header>
        </div>
      )
}
export default HomeHeader;
