import React, { useState, useEffect } from 'react'
import {Link, useHistory} from 'react-router-dom';
import Pagination from "./Pagination";
import axios from 'axios'

const Support = (props) => {
    const server = "http://18.179.138.213:3001";
    const history = useHistory();
    const [category, setCategory] = useState("support");
    const [references, setReferences] = useState([])
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(15);

    useEffect(() => {
        try{
            function get_data(){
                axios.get(
                    server + "/contact"
                  ).then((response) => {
                    let datas = response.data
                    if (response.status == 200){
                        const datas = response.data;
                        if (datas['data']) {
                            setReferences(datas['data'])
                        }
                    }
                  }).catch((err) => {
                  })
            }
            get_data();
        }catch (e){
        }
    }, [])
    const handleRefernece = (id, e) => {
        e.preventDefault();
        history.push("/support/" + id)
    }

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = references.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="sub_container">
        {/*-- 타이틀 --*/}
        <div className="sub_title_banner">
          <h2 className="tit">Q&A</h2>
        </div>
        {/*--// 타이틀 --*/}
        {/*-- 리본바 --*/}
        <div className="sub_reborn">
          <ul>
            <li><a>홈</a></li>
            <li><a>Q&A</a></li>
          </ul>
        </div>
        {/*--// 리본바 --*/}
        <div className="sub_conbox reperence">
          <div className="tablebox">
            <table className="h_table textcenter" cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th className="min">순번</th>
                  <th>제목</th>
                  <th className="min">게시일</th>
                  <th className="min">작성자</th>
                </tr>
              </thead>
              <tbody>
                { currentPosts.length === 0 ? <tr><td colSpan="4">등록된 자료가 없습니다.</td></tr>: currentPosts.map((reference, idx) => {
                  return (
                      <tr key={idx} onClick={(e) => handleRefernece(reference.id, e)}>
                          <td>{currentPosts.length - idx}</td>
                          <td>{reference.title}</td>
                          <td>{reference.date}</td>
                          <td>{reference.name}</td>
                      </tr>
                  )
              })}

              </tbody>
            </table>
          </div>
                <Pagination currentPage={currentPage} postsPerPage={postsPerPage} totalPosts={references.length} category={category} paginate={paginate}/>

        </div>

      </div>
    )
}
export default Support;