import React, {useState, useEffect } from 'react'
import bg from "../../assets/img/bg.jpg";
import photo1 from "../../assets/img/photo1.jpg";
import photo2 from "../../assets/img/photo2.jpg";
import photo3 from "../../assets/img/photo3.jpg";
import safety from "../../assets/img/safety.png";
import safetyw from "../../assets/img/safetyw.png";
import safetyvr from "../../assets/img/safetyvr.png";
import safetyq from "../../assets/img/safetyq.png";
import HomeReference from "./HomeReference";
import {get_reference} from "../../api/MainAPI";
import {Link} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";

const Home = () => {
    const [category, setCategory] = useState('construction');
    const [references, setReferences] = useState({
      construction : [],
      manufacture : [],
      safety : []
    });

    useEffect(() => {
      async function getData(){
        const response = await get_reference();
        if (response.status === 200){
            const datas = await response.data
            setReferences(datas.data)
          }
      }
      getData();

    }, []);
    const handleCategory = (e) => {
      const {name} = e.target.dataset;
      setCategory(name)
    }
    return (
        <>
            <div className="h_main_banner">
              <div className="textbox">
                <div className="textbox_inner">

                  <h2>
                    <span>안전을 위한</span> 최고의선택<br />
                    한국재해예방관리(주)
                  </h2>
                  <br />
                  <p className="fs_14">건설업 및 제조 현장에서 일어나는 산업재해를 예방하기 위해<br />
                    효율적이고 실질적인 맞춤 안전 컨설팅을 제공합니다.</p>
                  <br />
                  <p className="fs_14">
                    <Link to="/references">건설업</Link>
                    <Link to="/references/manufacture">제조업</Link>
                    <Link to="/references/example">중대재해사례</Link>
                    <Link to="/references/safety">안전진단</Link>
                  </p>
                  <p className="fs_14">
                    <Link to="/contact" className="btn_ask">Q&A</Link>
                  </p>
                </div>
              </div>
              <div className="imgbox">
                <img src={bg} alt="" />
              </div>
            </div>
            {/*-------// 배너 영역 끝 -------*/}
            <div className="main_contents">
              <section>
                <div className="info_list">
                  <div className="info_con">
                    <div className="imgbox"><img src={photo1} /></div>
                    <div className="textbox">
                      <h4 className="tit">건설/재해예방 기술지도</h4>
                      <p>건설공사현장의 안전활동을 추진함에 있어 안전관리비 사용방법 및 재해예방 조치등에 관하여 재해예방 전문 지도기관으로부터 기술지도를 받음으로써 안전사고예방은 물론 자율안전관리 시스템을 정착시키는데 그 목적을 두고 있습니다.</p>
                      <Link to="/business/technicalguidance">자세히 보기</Link>
                    </div>
                  </div>
                  <div className="info_con">
                    <div className="imgbox"><img src={photo2} /></div>
                    <div className="textbox">
                      <h4 className="tit">제조/안전관리자 위탁</h4>
                      <p>산업재해를 예방하기 위해 한국재해예방관리원(주)는 전문적인 안전보건 관리체제를 확립하고 지도합니다.</p>
                      <Link to="/business/safetymanagement">자세히 보기</Link>
                    </div>
                  </div>
                  <div className="info_con">
                    <div className="imgbox"><img src={photo3} /></div>
                    <div className="textbox">
                      <h4 className="tit">안전진단</h4>
                      <p>건설현장 재해발생 위험에 있어 건설안전 전반에 대한 위험요인을 진단하여 분야별 문제점 및 개선대책을 제시합니다.</p>
                      <Link to="/business/adjacent">자세히 보기</Link>
                    </div>
                  </div>
                </div>
              </section>
              <section className="bg_light">
                <div className="reference_list">
                  {/*
                  <ul className="tab">
                    <li className={category === 'construction' ? 'on' : ''} onClick={handleCategory} data-name="construction">건설업 자료실</li>
                    <li className={category === 'manufacture' ? 'on' : ''} onClick={handleCategory} data-name="manufacture">제조업 자료실</li>
                    <li className={category === 'safety' ? 'on' : ''} onClick={handleCategory} data-name="safety">안전진단 자료실</li>
                  </ul>
                  */}
                  <HomeReference
                    category={category}
                    references={references}
                  />
                </div>
              </section>
              <section>
                <Container>
                  <Row>
                    <h4 style={{fontWeight : "600"}}>유관기관</h4>
                  </Row>
                  <Row style={{margin : "30px 0px"}}>
                    <Col md={3}><a href="https://www.kosha.or.kr/kosha/data/mediaBankMain.do" target="_blank"><img className="relation-img" src={safety}/></a></Col>
                    <Col md={3}><a href="https://www.kosha.or.kr/kosha/ebook/monthly.do" target="_blank"><img className="relation-img" src={safetyw}/></a></Col>
                    <Col md={3}><a href="https://360vr.kosha.or.kr/main" target="_blank"><img className="relation-img" src={safetyvr}/></a></Col>
                    <Col md={3}><a href="https://360vr.kosha.or.kr" target="_blank"><img className="relation-img" src={safetyq}/></a></Col>
                  </Row>
                </Container>
              </section>
            </div>
        </>
    )
}
export default Home