import React, {useState, useEffect} from "react";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import Editor from './EditorComponent'
import axios from 'axios'
const ReferenceMake = () => {
    const server = "http://18.179.138.213:3001"
    let newDate = new Date()
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let t_date, t_month;

    if (date_raw<10)  {  t_date ="0"+date_raw.toString()} else {  t_date =date_raw.toString()}
    if (month_raw<10)  {  t_month ="0"+month_raw.toString()} else {  t_month =month_raw.toString()}
    let parse_day = year + "-" + t_month + "-" + t_date
    const [checked, setChecked] = useState(false)
    const [files, setFiles] = useState({})
    const [desc, setDesc] = useState("");
    const [inputs, setInputs] = useState({
    "title" : "",
    "category" : "construction",
    "content" : "",
        "date" : parse_day
    })
    const handleCategory = (e) => {
        setInputs({
            ...inputs,
            "category" : e.target.value
        })
    }
    const {title, category, date} = inputs
    const handleSave = async(e) => {
        e.preventDefault();
        if(title === '') { return alert('제목을 입력해주세요')}
        if(desc === '') { return alert('내용을 입력해주세요')}
        if(category === '') {return alert('카테고리를 선택해주세요')}

        let formData = new FormData()
        let token = sessionStorage.getItem('token')
        for (let i=0; i< files.length; i++) {
          formData.append(`files`, files[i])
        }

        formData.append('title', title)
        formData.append('content', desc)
        formData.append('files', files);
        formData.append('category', category)
        formData.append('notice', checked)
        formData.append('date', date)
        await axios.post(
            server + "/admin/reference-make", formData,{
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': token
            }}
          ).then((response) => {
            let datas = response.data
            if (response.status == 200){
              alert("저장되었습니다.")
              window.location.replace("/reference/" + datas['data'])


            }
          }).catch((err) => {
          })

      }
    const onEditorChange = (value) => {
        setDesc(value)

    }
    const handleChange = (e) => {
        let {value, name} = e.target
        setInputs({
          ...inputs,
          [name] : value
        })
      }
    const handleFile = (e) => {
    let t_files = e.target.files
        setFiles(t_files)
    }
    return (
        <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header className="d-flex" style={{justifyContent : "space-between"}}>
                <Card.Title as="h4">게시글 작성</Card.Title>
                <div>

                  <Button
                    variant="primary"
                    size="sm"
                    onClick={handleSave}
                  >저장</Button>
                </div>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pl-1" md="12">
                      <Form.Group>
                        <label>제목</label>
                        <Form.Control
                          placeholder="제목을입력하세요"
                          type="text"
                          name="title"
                          value={title}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>카테고리</label>
                        <Form.Control as="select" value={category} onChange={handleCategory}>
                          <option name="construction" value="construction">건설업</option>
                          <option name="manufacture" value="manufacture">제조업</option>
                          <option name="example" value="example">중대재해사례</option>
                          <option name="safety" value="safety">안전진단</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>공지여부</label>
                        <Form.Control
                          type="checkbox"
                          name="checked"
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>게시일</label>
                        <Form.Control
                          type="date"
                          name="date"
                          value={date}
                          placeholder="2020-01-01"
                                    onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-1" md="12">
                      <Form.Group>
                        <label>파일</label>
                        <Form.Control
                          type="file"
                          name="files"
                          multiple
                          onChange={handleFile}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="12">
                      <Editor value={desc|| ""} onChange={onEditorChange} />
                    </Col>
                  </Row>

                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="info"
                    onClick={handleSave}
                  >
                    저장하기
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
    </>
    )
}
export default ReferenceMake;
